import React, { useState, useEffect } from "react";
import axios from "axios";
import CaptionAlt from "./CaptionAlt";
import Card from "./Card";
import AnchorIcon from "./../assets/social/anchor.svg";
import AppleIcon from "./../assets/social/apple.svg";
import GoogleIcon from "./../assets/social/google.svg";
import YoutubeAltIcon from "./../assets/social/youtube2.svg";
import SpotifyIcon from "./../assets/social/spotify.svg";
import TelegramIcon from "./../assets/social/telegram.svg";
import { PrimaryButton } from "./Buttons";

const GetEdified = () => {
  const [loading, setLoading] = useState(false);
  const [sermons, setSermons] = useState([]);
  const [error, setError] = useState("");
  const [readMore, setReadMore] = useState(false);

  const getSermons = async () => {
    setLoading(true);

    try {
      const sermonsData = await axios.get(
        "https://nim-backend.herokuapp.com/api/sermons?sort=createdAt:DESC&pagination[page]=1&pagination[pageSize]=3"
      );

      let dataArray = sermonsData.data.data;

      setSermons(dataArray);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getSermons();
  }, []);

  const handleReadMore = () => {
    setReadMore(!readMore);
  };

  return (
    <div className="">
      <CaptionAlt
        headerText={"Get Edified"}
        description="Stay up to date with our latest sermons and get blessed"
      />
      <div className="flex justify-between flex-col lg:flex-row mt-10 lg:mb-24">
        {loading ? (
          <div role="status" className="flex justify-center mt-10">
            <svg
              aria-hidden="true"
              className="w-12 h-12 text-gray-200 animate-spin fill-blue"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
        ) : (
          sermons.map((sermon) => {
            let {
              anchor_link,
              applepodcast_link,
              caption,
              googlepodcast_link,
              image_url,
              spotify_link,
              telegram_link,
              title,
              youtube_link,
            } = sermon.attributes;
            return (
              <Card
                url={image_url}
                title={title}
                description={readMore ? caption : caption.substr(0, 150)}
                toggleReadMore={readMore ? `Read Less` : `Read More`}
                handleReadMore={handleReadMore}
                footer={
                  <div className="flex justify-between">
                    <div className="flex">
                      <a href={anchor_link} target="_blank" rel="noreferrer">
                        <img src={AnchorIcon} alt="Anchor Icon" />
                      </a>
                      <a
                        href={applepodcast_link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={AppleIcon} alt="Apple Icon" />
                      </a>
                      <a
                        href={googlepodcast_link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={GoogleIcon} alt="Google Icon" />
                      </a>
                      <a href={youtube_link} target="_blank" rel="noreferrer">
                        <img src={YoutubeAltIcon} alt="Youtube Icon" />
                      </a>
                      <a href={spotify_link} target="_blank" rel="noreferrer">
                        <img src={SpotifyIcon} alt="Spotify Icon" />
                      </a>
                      {/* <a href={telegram_link} target="_blank" rel="noreferrer">
                        <img src={TelegramIcon} alt="Telegram Icon" />
                      </a> */}
                    </div>
                  </div>
                }
              />
            );
          })
        )}
      </div>
      <div className="flex justify-end lg:mt-0 lg:hidden mt-10">
        <PrimaryButton btnText={"View All"} href="resources" />
      </div>
    </div>
  );
};

export default GetEdified;
